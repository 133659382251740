import React, { useState, useEffect } from 'react';
import './MenuPage.css'; // 导入 CSS 文件
import menuBg from './image/Lamb_rib.png'; // 导入图片

const MenuPage = () => {
  const [activeTab, setActiveTab] = useState('a-la-carte'); // 保存当前选中的 Tab

  useEffect(() => {
    document.title = "RFG Menu"; // 设置页面标题
  }, []);

  // 定义不同 Tab 的内容
  const renderMenuContent = () => {
    switch (activeTab) {
      case 'a-la-carte':
        return (
          <div>
            <h2>À La Carte Menu</h2>

            <h3>Amuse-bouche</h3>
            <ul>
              <li><strong>Fresh Jumbo Oyster</strong><br /> Cognac Sauce or Sake Sauce <br /> Fermented Fruit Slush + $3 ea. - <strong>$7 ea.</strong></li>
              <li><strong>Pan-seared Hokkaido Scallop</strong><br /> Pumpkin and Champagne - <strong>$9 ea.</strong></li>
            </ul>

            <h3>Entrées</h3>
            <ul>
              <li><strong>Eye Fillet Tartare</strong><br /> Capers, Onion, Dijon, Wasabi - <strong>$18</strong></li>
              <li><strong>Torched King Salmon</strong><br /> Grapefruit, Yuzu, Basil - <strong>$23</strong></li>
              <li><strong>Charcoal Grill Argentina Red Prawn</strong><br /> Garlic, Chili, Parsley - <strong>$20</strong></li>
              <li><strong>Single Slow Cooked Lamb Rib</strong><br /> Honest Rum Yogurt, Fennel Salad, Herbs - <strong>$14</strong></li>
              <li><strong>Fried Cheese Dumplings</strong><br /> Brie & Mozzarella, Honey, Grapefruit, La Cigogne - <strong>$12</strong></li>
            </ul>

            <h3>Les Plats Principaux (Mains)</h3>
            <ul>
              <li><strong>Market Fish</strong><br /> Baby Pea Puree - <strong>$36</strong></li>
              <li><strong>Slow Cooked Lamb Ribs</strong><br /> Honest Rum Yogurt, Fennel Salad, Herbs - <strong>$38</strong></li>
              <li><strong>Vegetable Dumplings</strong><br /> Mushroom, Carrot, Truffle Potato, Broccoli, Cheese - <strong>$28</strong></li>
              <li><strong>RFG Beef Wellington</strong><br /> Eye fillet, Mixed Mushrooms, Truffle, Nuts, Prosciutto (Takes 1.5 hrs to cook) - <strong>$130</strong></li>
              <li><strong>King Salmon Salad</strong><br /> Grilled King Salmon, Capers, Mandarin, Balsamic - <strong>$22</strong></li>
            </ul>

            <h3>Accompagnments (Sides)</h3>
            <ul>
              <li><strong>Honey-Roasted Carrot</strong> - Sesame - <strong>$16</strong></li>
              <li><strong>Quinoa Salad with Citrus</strong> - White Quinoa, Mandarin, Citrus & Herb Jus - <strong>$14</strong></li>
              <li><strong>Truffle Fries</strong> - Parmesan Cheese - <strong>$13</strong></li>
              <li><strong>Cheese Platter</strong> - 3 Chef’s picked cheese - <strong>$32</strong></li>
            </ul>

            <h3>Kamado Grill</h3>
            <ul>
              <li><strong>Eye Fillet (160g)</strong> - Grass-Fed, NZ - <strong>$38</strong></li>
              <li><strong>Angus Scotch Fillet (300g)</strong> - Grass-Fed Angus, Canterbury, NZ - <strong>$46</strong></li>
              <li><strong>Tomahawk (900g)</strong> - Grass-Fed, NZ (Takes 1.5 hrs to cook) - <strong>$120</strong></li>
              <li><strong>28-Days Dry Aged Angus Sirloin</strong> - Grass-Fed, Hawke’s Bay, NZ - <strong>$28/100g</strong></li>
              <li><strong>28-Days Dry Aged Angus Scotch Fillet</strong> - Grass-Fed, Canterbury, NZ - <strong>$32/100g</strong></li>
              <li><strong>28-Days Dry Aged Grain-Fed Angus Tomahawk</strong> - Grain-Fed, Canterbury, NZ - <strong>$32/100g</strong></li>
              <li><strong>45-Days Dry Aged Tomahawk</strong> - Grass-Fed, Hawke’s Bay, NZ - <strong>$38/100g</strong></li>
              <li><strong>A5 Wagyu Sirloin</strong> - Kagoshima, Japan (Booking Required) - <strong>$55/100g</strong></li>
            </ul>

            <h3>Sauce & Butter</h3>
            <ul>
              <li>Red Wine Jus, Cognac Green Pepper, Truffle Butter, Mushroom Sauce, Wasabi & Dijon Sauce, Balsamic Herb Reduction, Blue Cheese Butter, Black Garlic Butter</li>
            </ul>

            <p style={{ fontSize: '12px', marginTop: '20px' }}>
              <em>
                ALL DISHES MAY CONTAIN TRACES OF GLUTEN, NUTS & DAIRY. PLEASE INFORM OUR TEAM OF ANY SPECIAL DIETARY REQUIREMENTS.
              </em>
            </p>
          </div>
        );

      case 'sharing-food-menu':
        return (
          <div>
            <h2>RAW FLAME GRILL</h2>

            <ul>
              <li>
                <strong>Whole RFG Tomahawk</strong> - <strong>$120</strong><br />
                <em>900g</em><br />
                Charcoal grilled, Red Wine Jus, Horseradish, Homemade Soy Sauce<br />
                <em>(Serves 2-3 people, Takes 1.5 hrs to cook)</em>
              </li>

              <li>
                <strong>RFG Full Rack Lamb Rack</strong> - <strong>$130</strong><br />
                <em>12 Racks</em><br />
                Parsley, Dijon Mustard, Mint<br />
                <em>(Serves 2 people, 1 Day Booking Ahead)</em>
              </li>

              <li>
                <strong>RFG Beef Wellington</strong> - <strong>$130</strong><br />
                Eye fillet, Mixed Mushrooms, Truffle Pasta, Prosciutto<br />
                <em>(Serves 2-3 people, Takes 1.5 hrs to cook)</em>
              </li>

              <li>
                <strong>RFG GAME MEAT</strong> - <strong>$160</strong><br />
                3 Kinds of Wild Game Meat (Wild Venison, Wild Pork, Wild Quail, Wild Rabbit, Wild Hare, Wallaby, Wild Goat, Wild Arapawa Sheep, Wild Fallow), Game Sauce, Beetroot Sauce<br />
                <em>(Serves 2 people, 1 Week Booking Ahead, Subject to Availability)</em>
              </li>
            </ul>
          </div>
        );
      case 'beverage-list':
        return(
          <div>
            <h2>Cocktails</h2>
            <ul>
              <li><strong>Cold Brew Coffee Martini</strong><br /> Tito’s Vodka, Cold Brew Coffee, Mr.Black Coffee Liqueur, Vanilla - <strong>$23</strong></li>
              <li><strong>French Martini</strong><br /> Tito’s Vodka, Chambord, Pineapple - <strong>$20</strong></li>
              <li><strong>Passion Passion</strong><br /> Tito’s Vodka, Passion Fruit, Lime - <strong>$23</strong></li>
              <li><strong>Cosmopolitan</strong><br /> Tito’s Vodka, Cointreau, Cranberry, Lime - <strong>$20</strong></li>
              <li><strong>Aviation</strong><br /> Hayman’s Gin, Maraschino, Violette, Lemon - <strong>$20</strong></li>
              <li><strong>Mojito</strong><br /> Diplomatico Rum, Mint, Lime, Bubble - <strong>$23</strong></li>
              <li><strong>Dark & Stormy</strong><br /> Flor de Cana 7 aged Rum, Lime, Ginger Beer - <strong>$20</strong></li>
              <li><strong>Margarita</strong><br /> Altos Tequila, Cointreau, Agave, Lime, Salt - <strong>$20</strong></li>
              <li><strong>Hawke’s Bay Negroni</strong><br /> Hawke’s Bay Gin, Hawke’s Bay Campari, Antica Formula - <strong>$24</strong></li>
              <li><strong>Old Fashioned</strong><br /> Woodford Reserve Bourbon, Angostura, Orange, Sugar - <strong>$24</strong></li>
              <li><strong>The Martini</strong><br /> Vodka OR Gin, Dry OR Dirty, Olive OR Twist - <strong>MP</strong></li>
            </ul>

            <h2>Sparkling Wines <span style={{ float: 'right' }}>125ml / 750ml</span></h2>
            <ul>
              <li><strong>Le Contesse Prosecco Brut</strong> - Italy - <strong>$13 / $50</strong></li>
              <li><strong>Bird in Hand Sparkling Rosé</strong> - AU - <strong>$16 / $55</strong></li>
              <li><strong>Laurent-Perrier La Cuvée Brut Champagne</strong> - Tours-sur-Marne, France - <strong>$26 / $115</strong></li>
              <li><strong>Gosset Grande Reserve Brut Champagne</strong> - Aÿ, France - <strong>$28 / $120</strong></li>
            </ul>

            <h2>Still Wines <span style={{ float: 'right' }}>150ml / 750ml</span></h2>
            <ul>
              <li><strong>Cirro Sauvignon Blanc</strong> - Marlborough, NZ - <strong>$16 / $55</strong></li>
              <li><strong>La Grande Colombe Chardonnay</strong> - France - <strong>$16 / $55</strong></li>
              <li><strong>Prophet’s Rock Pinot Gris (off-dry)</strong> - Central Otago, NZ - <strong>$18 / $75</strong></li>
              <li><strong>Saint Clair Pionner Block12 Gewurztraminer</strong> - Marlborough, NZ - <strong>$17 / $65</strong></li>
              <li><strong>Triennes Rosé</strong> - Provence, France - <strong>$16 / $55</strong></li>
              <li><strong>CherryCream Pinot Noir</strong> - California, USA - <strong>$17 / $65</strong></li>
              <li><strong>Escarpment NOIR Pinot Noir</strong> - Martinborough, NZ - <strong>$19 / $78</strong></li>
            </ul>

            <h2>Sparkling Wines <span style={{ float: 'right' }}>750ml</span></h2>
            <ul>
              <li><strong>Gosset Blanc de Blanc Champagne</strong> - Aÿ, France - <strong>$190</strong></li>
              <li><strong>Franck Bonville Grand Cru Blanc de Blanc</strong> - Champagne, France - <strong>$120</strong></li>
            </ul>

            <h2>Still Wines <span style={{ float: 'right' }}>750ml</span></h2>
            <ul>
              <li><strong>Starborough Sauvignon Blanc</strong> - Marlborough, NZ - <strong>$50</strong></li>
              <li><strong>Greywacke Sauvignon Blanc</strong> - Marlborough, NZ - <strong>$65</strong></li>
              <li><strong>2023 Kumeu River Maté’s Vineyard Chardonnay</strong> - Kumeu, NZ - <strong>$145</strong></li>
              <li><strong>Domaine Chevallier Chablis</strong> - Burgundy, France - <strong>$85</strong></li>
              <li><strong>Two Paddocks Pinot Noir Rosé</strong> - Central Otago, NZ - <strong>$75</strong></li>
              <li><strong>Pencarrow Pinot Noir</strong> - Martinborough, NZ - <strong>$70</strong></li>
              <li><strong>2022 Felton Road “Calvert” Pinot Noir</strong> - Central Otago, NZ - <strong>$135</strong></li>
              <li><strong>2022 Felton Road “Cornish Point” Pinot Noir</strong> - Central Otago, NZ - <strong>$135</strong></li>
            </ul>

            <h2>Spirits</h2>
            <ul>
              <li><strong>House Pour</strong> - Tito’s Vodka, Diplomatico Rum, Flor de Cana 7 Aged Rum, Haymans London Dry Gin, Woodford Reserve Bourbon, Altos Tequila - <strong>$14</strong></li>
            </ul>

            <h2>Gin</h2>
            <ul>
              <li><strong>East Block 200 Gin</strong> - Hawke’s Bay, NZ - <strong>$15</strong></li>
              <li><strong>The Botanist</strong> - Scotland - <strong>$16</strong></li>
              <li><strong>Monkey 47 Dry Gin</strong> - Germany - <strong>$18</strong></li>
            </ul>

            <h2>Whisky</h2>
            <ul>
              <li><strong>Woodford Reserve Double Oaked</strong> - <strong>$20</strong></li>
              <li><strong>Jura Seven Wood</strong> - <strong>$20</strong></li>
              <li><strong>The GlenAllachie 15 Years Old</strong> - <strong>$23</strong></li>
              <li><strong>1994 Benriach Cask #1858 PX Sherry 27 Years</strong> - <strong>$58</strong></li>
            </ul>

            <h2>Beer</h2>
            <ul>
              <li><strong>Asahi Dry</strong> - <strong>$11</strong></li>
              <li><strong>Asahi Zero 0%</strong> - <strong>$10</strong></li>
              <li><strong>Hallertau #2 Pale Ale</strong> - <strong>$13</strong></li>
              <li><strong>Hallertau #1 Low Carb Lager</strong> - <strong>$13</strong></li>
            </ul>

            <h2>Non-Alcoholic</h2>
            <ul>
              <li><strong>Homegrown Juice</strong> (Apple / Orange / Pineapple) - <strong>$6</strong></li>
              <li><strong>Coke / Coke Zero / Sprite / Ginger Beer</strong> - <strong>$6</strong></li>
              <li><strong>Antipodes 1L</strong> (Sparkling / Still) - <strong>$12</strong></li>
              <li><strong>Tea</strong> (English Breakfast / Green) - <strong>$6</strong></li>
            </ul>
          </div>

        );
      default:
        return null;
    }
  };

  return (
    <div className="page-container">
      <div className="content-container">

        {/* 顶部部分 */}
        <div className="top-section">
          <div className="menu-image-section">
            <img src={menuBg} alt="Lamb rib" className="menu-image" />
          </div>
        </div>

        {/* 菜单标签部分 */}
        <div className="menu-tabs-section">
          <a
            href="#"
            className={`menu-tab ${activeTab === 'a-la-carte' ? 'active' : ''}`}
            onClick={() => setActiveTab('a-la-carte')}
          >
            À La Carte
          </a>
          <a
            href="#"
            className={`menu-tab ${activeTab === 'sharing-food-menu' ? 'active' : ''}`}
            onClick={() => setActiveTab('sharing-food-menu')}
          >
            Sharing Food Menu
          </a>
          <a
            href="#"
            className={`menu-tab ${activeTab === 'beverage-list' ? 'active' : ''}`}
            onClick={() => setActiveTab('beverage-list')}
          >
            Beverage List
          </a>
        </div>

        {/* 动态内容部分 */}
        <div className="menu-section">
          {renderMenuContent()}
        </div>
      </div>
    </div>
  );
};

export default MenuPage;
































